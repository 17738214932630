.logo {
  position: absolute;
  top: 0;
  left: 0;
}

.logoImg {
  height: 300px;
}

@media screen and (min-width: 320px) and (max-width: 600px) {
  .logo {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}