@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;800&family=Oswald:wght@300&display=swap');


*{
  margin: 0;
  padding: 0;
}

body{
  width: 100%;
  height: 100vh;
  font-family: Oswald, sans-serif;
  list-style-type: none;
}

h2 {
  font-size: 50px;
  font-weight: 200;
  text-transform: uppercase;
  opacity: 1;
}

h3 {
  font-size: 20px;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.App {
  width: 100%;
  height: 100vh;
}

.textme{
  width: 300px;
  height: 70px;
  position: fixed;
  right: 0;
  top: 50%;
}

.center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.flex {
  display: flex;
}

.gradient {
  color: transparent;    
  -webkit-background-clip: text;
  background-clip: text;
  background-color: rgb(181,120,255);
  background-image: linear-gradient(90deg, rgba(181,120,255,1) 0%, rgba(255,128,69,1) 50%, rgba(255,210,106,1) 100%);
}

.gradient2 {
  color: transparent;    
  -webkit-background-clip: text;
  background-clip: text;
  background-color: rgb(255,128,69);
  background-image: linear-gradient(90deg, rgba(255,128,69,1) 0%, rgba(255,210,106,1) 100%);
}


.link {
  text-decoration: none;
  cursor: pointer;
  color: #d39850;
  transition: 0.3s;
  font-size: 20px;
  text-align: center;
}

.link:hover {
  color: white;
}

.title {
  display: flex;
  justify-content: center;
}


.block-body {
  background-color: #111111;
  font-size: 20px;
  color:  white;
  display: flex;
  justify-content: center;
  height: 300px;
} 

.block {
  width: 600px;
}

.hr {
  margin-top: 30px;
  border: none;
  height: 1px;
  background-color: #ddd;
}

.contact-btn {
  text-transform: uppercase;
}

.contact-btn-body {
  font-size: 20px;
  cursor: pointer;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 65px;
  border:  1px solid white;
  border-radius: 10px;
  transition: 0.3s;
  color: white;
}

.contact-btn-body:hover{
  color: rgba(255,81,0,0.8603816526610644);
  background-color: white;
}

.contact-h2 {
  margin-bottom: 30px;
}