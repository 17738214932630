.footer {
  height: 260px;
  background-color: #f7f7f7;
}

.phone {
  font-size: 25px;
  position: absolute;
  margin: 30px;
  text-transform: uppercase;
}

.media {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 110px;
}

.media-icon {
  cursor: pointer;
  opacity: 0.8;
  width: 25px;
  height: 25px;
  transition: 0.3s;
  padding: 5px
}

.media-icon:hover {
  opacity: 1;
}

.privacy {
  display: flex;
  justify-content: center;
  color: black;
}

.list-footer {
  font-size: 15px;
  transition: 0.3s;
  font-weight: 500;
  text-decoration: none;
  color: black;
}

.list-footer:hover {
  opacity: 0.6
}

.rights {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 110px;
  font-weight: 300;
  font-size: 16px
}

@media screen and (min-width: 320px) and (max-width: 800px) {
  .media {
    justify-content: flex-end;
    margin-right: 50px;
  }

  .privacy {
    margin-top: 50px;
  }

  .phone {
    font-size: 20px;
  }
}