.about-image{
	display: flex;
	justify-content: center;
	align-items: center;
    background: rgb(181,120,255);
    background: linear-gradient(90deg, rgba(181,120,255,1) 0%, rgba(255,128,69,1) 50%, rgba(255,210,106,1) 100%);	height: 400px;
	background-size: cover;
	height: 80vh;
}

.steps {
	color:  white;
	font-size: 20px;
	text-transform: uppercase;
}

.step-body {
	background-color: #17191a;
	color:  white;
	padding: 100px 0;
}

.first-step {
	display: flex;
	justify-content: space-around;
}

.step-title {
	font-size: 30px;
	text-transform: uppercase;
}

.step-title-dis {
	margin-bottom: 20px;
}

.step-dis {
	width: 50%;
}

.step-img {
	width: 25%;
}

.first-step-img {
	background-image: url('../pages/imagesPages/star-ceiling.jpg');
	background-size: cover;
	height: 400px;
	margin-right: 30px;
}

.second-step-img {
	background-image: url('../pages/imagesPages/slider.jpg');
    background-size: cover;
    width: 100%;
	height: 400px;
	margin: 20px;
}

.third-step {
	display: flex;
	margin: 100px 0;
}

.goals-body {
	display: flex;
	margin-top: 30px;
}

.goal {
	opacity: 0.9;
	margin-left: 50px;
}

.hr-goals {
	width: 90%;
	border: none;
	border-top: 1px solid rgba(255,81,0,0.8603816526610644);
}


.third-step-title {
	font-size: 30px;
	text-transform: uppercase;
	padding-left: 30px;
	width: 25%;
}

.third-step-dis {
	width: 60%;
}

.installation {
	background-image: url('../pages/imagesPages/slider.jpg');
	background-repeat: no-repeat;
    background-size: cover;
    width: 80%;
	height: 400px;
	margin: 20px;
}

.fifth-step {
	margin-top: 50px;
	display: flex;
	justify-content: space-around;
}

.fifth-step-dis {
	width: 75%;
	margin-left: 150px;
}

.finish {
	background-image: url('../pages/imagesPages/slider.jpg');
	background-repeat: no-repeat;
    background-size: cover;
    width: 60%;
	height: 600px;
	margin: 20px;
}

.warranty {
	background-color: rgb(181,120,255);
    background-image: linear-gradient(90deg, rgba(181,120,255,1) 0%, rgba(255,128,69,1) 50%, rgba(255,210,106,1) 100%);
	width: 50vw;
}

.warrantyBody {
	margin: 100px 0;
}

.warrantyInside {
	background-color: #17191a;
	height: 50vh;
	margin: 10px;
} 

.warrantyText {
	font-size: 50px;
	text-transform: uppercase;
}

@media screen and (min-width: 320px) and (max-width: 600px) {
	.about-image {
		height: 90vh;
	}

	.warranty {
		width: 80vw;
		text-align: center;
	}

	.warrantyInside {
		background-color: #17191a;
		height: 50vh;
		margin: 5px;
	} 

	.warrantyText {
		font-size: 30px;
		padding: 10px;
	}

	.step-body {
		padding: 50px 0
	}

	.steps {
		text-align: center;
		position: relative;
	}

	.first-step {
		display: block;
		width: 100%;
	}

	.step-title {
		width: 100%;
		text-align: center;
	}

	.step-dis {
		width: 90%;
		margin: auto;
	}

	.first-step-img {
		height: 300px;
		width: 100%;
		margin: 30px 0;
	}

	.second-step-img {
		height: 300px;
		width: 100%;
		margin: 30px 0;
	}

	.third-step {
		display: block;
		width:  95%;
		margin: 50px 0;
	}

	.third-step-title {
		width: 90%;
		text-align: center;
		font-size: 30px;
	}

	.third-step-dis {
		width: 90%;
		padding-left: 0;
		margin: 20px;
	}

	.step-title-dis {
		font-size: 35px;
	}

	.goal {
		margin-left: 20px;
	}

	.installation {
		width: 100%;
		height: 300px;
		margin: 30px 0;
	}

	.fifth-step {
		display: block;
		width: 100%;
		margin: 0;
	}

	.fifth-step-dis {
		width: 90%;
		margin-left: 0px;
		margin: 20px;
	}

	.finish {
	    width: 100%;
		height: 300px;
		margin: 20px 0;
	}
}