.c-heroImageGrid {
	position: relative;
	overflow: hidden;
	height: 50vw;
	background: #111111;
}

.c-heroImageGrid .container {
	display: flex;
	align-items: flex-end;
	position: absolute;
	top: -20vw;
	left: -8vw;
	transform: rotateZ(45deg);
	height: 100vw;
	width: 107vw;
}

.c-heroImageGrid .container .column {
	overflow: hidden;
	position: relative;
}

.c-heroImageGrid .container .column:nth-of-type(1) {
	border-right: 8px solid white;
	width: 25%;
	height: 66vw;
}

.c-heroImageGrid .container .column:nth-of-type(1):before {
	position: absolute;
	top: 8.4vw;
	left: 8.1vw;
	background-repeat: no-repeat;
	background-size: cover;
	transform: rotateZ(-45deg);
	content: "";
	width: 37vw;
	height: 38vw;
	background-image: url(https://laqfoil.com/wp-content/uploads/2019/12/Reflective-stretch-Ceilings-hotel-loby.jpg);
}


.c-heroImageGrid .container .column:nth-of-type(2) {
	display: flex;
	flex-direction: column-reverse;
	width: 75%;
	height: 109.1vw;
}

.c-heroImageGrid .container .column:nth-of-type(2) .row {
	border-top: 8px solid #fff;
}


.c-heroImageGrid .container .column:nth-of-type(2) .row:nth-of-type(1) {
	position: relative;
	overflow: hidden;
	height: 50vw;
}

.c-heroImageGrid .container .column:nth-of-type(2) .row:nth-of-type(1):before {
	position: absolute;
	top: -10.1vw;
	left: -14.3vw;
	background-repeat: no-repeat;
	background-size: cover;
	transform: rotateZ(-45deg);
	content: "";
	width: 65vw;
	height: 39vw;
	background-image: url(https://th.bing.com/th/id/R.2a3771ff98aef2c4315153cfd31aa0cb?rik=KaiG%2f5WAzpx%2bGA&pid=ImgRaw&r=0);
}

.c-heroImageGrid .container .column:nth-of-type(2) .row:nth-of-type(2) {
	display: flex;
	height: 40.5vw;
}

.c-heroImageGrid .container .column:nth-of-type(2) .row:nth-of-type(3) {
	border-top: none;
}

.c-heroImageGrid .container .column:nth-of-type(2) .row:nth-of-type(2) div:nth-of-type(1) {
	overflow: hidden;
	width: 30%;
	background-color: #8B8B8A;
}

.c-heroImageGrid .container .column:nth-of-type(2) .row:nth-of-type(2) div:nth-of-type(1) .text {
	width: 100%;
	height: 100%;
	overflow: visible;
	transform: rotateZ(-45deg);
	position: relative;
}

.c-heroImageGrid .container .column:nth-of-type(2) .row:nth-of-type(2) div:nth-of-type(1) .text h6 {
	position: absolute;
	color: #fff;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 1.35vw;
	width: 20vw;
	top: 16vw;
	left: 7vw;
	line-height: 1.55vw;
}

.c-heroImageGrid .container .column:nth-of-type(2) .row:nth-of-type(2) div:nth-of-type(1) .text p {
	text-align: right;
	position: absolute;
	color: #fff;
	font-size: 0.9vw;
	width: 18vw;
	top: 23.5vw;
	left: -3.5vw;
}

.c-heroImageGrid .container .column:nth-of-type(2) .row:nth-of-type(2) div:nth-of-type(2) {
	position: relative;
	width: 70%;
	border-left: 8px solid white;
	overflow: hidden;
}

.c-heroImageGrid .container .column:nth-of-type(2) .row:nth-of-type(2) div:nth-of-type(2):before {
	position: absolute;
	top: -12vw;
	left: -13vw;
	width: 70vw;
	height: 50vw;
	background-repeat: no-repeat;
	background-size: cover;
	transform: rotateZ(-45deg);
	content: "";
	background-image: url(https://i.pinimg.com/originals/d5/83/cc/d583cc9412da3663fa5b9799f990b8fb.jpg);
}

.c-heroImageGrid .container .column:nth-of-type(2) .row:nth-of-type(3) {
	position: relative;
	height: 18.6vw;
	overflow: hidden;
}

.c-heroImageGrid .container .column:nth-of-type(2) .row:nth-of-type(3):before {
	position: absolute;
	top: 0;
	left: 28vw;
	width: 26vw;
	height: 33vw;
	background-repeat: no-repeat;
	background-size: 100%;
	transform: rotateZ(-45deg);
	content: "";
	background-image: url(https://alamiva.com/media/images/gallery_img12.original.jpg);
}
