.start {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: rgb(181,120,255);
  background: linear-gradient(90deg, rgba(181,120,255,1) 0%, rgba(255,128,69,1) 50%, rgba(255,210,106,1) 100%);
}

.slogan {
  font-size: 80px;
  text-transform: uppercase;
  opacity: 0.9;
  width: 80vw;
  text-align: center;
}

.btn-slogan {
  color:  white;
  align-items: center;
  font-size: 25px;
  padding: 20px 60px;
  border:  2px solid white;
  border-radius: 10px;
  cursor: pointer;
}

.btn-slogan:hover {
  background-color: white;
  color: rgba(255,81,0,0.8603816526610644);
  transition: 0.5s;
}

.btn-slogan-body {
  position: relative;
  top: 50px;
  display: flex;
  justify-content: center;

}

@media screen and (min-width: 320px) and (max-width: 800px) {
  .slogan {
    margin: auto;
    font-size: 37px;
    margin-top: 150px;
    width: 90vw;
    margin-bottom: 30px;
  }

  .btn-slogan-body {
    top: 0
  }

}