.dis-container {
  background-color: #17191a;
  color: white;
}

.Dis {
  display: flex;
  justify-content: space-between;
}

.img-block-1 {
  height: 500px;
  width: 50%;
  margin: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url('../pages/imagesPages/slot.jpg');
}


.first-title {
  padding-top: 50px;
}

.img-after {
  width: 50%;
  margin-top: 150px;
}

.textAbout {
  width: 50%;
  font-size: 20px;
  line-height: 1.7;
  font-weight: 400;
  padding-bottom: 20px;
}

.starCeiling {
  background-image: url('../pages/imagesPages/star-ceiling.jpg');
}

.matteCeiling {
  background-image: url('../pages/imagesPages/matte.jpg');
}

.glossCeiling{  
  background-image: url('../pages/imagesPages/gloss.jpg');
}

.backlightCeiling{
  background-image: url('../pages/imagesPages/backlit.jpg');
}

.ledSolutions{
  background-image: url('../pages/imagesPages/led.jpg');
}

.perforatedCelings {
  background-image: url('../pages/imagesPages/track.jpg');
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  margin-top: 50px;
}

.grid-item {
  background-size: cover;
  background-position: center;
  height: 600px;
  width: 100%;
  font-size: 30px;
}

.type {
  text-transform: uppercase;
  padding: 10px;
}

@media screen and (min-width: 320px) and (max-width: 600px) {
  .Dis {
    display: block;
    padding: 50px 0;
  }

  .img-block-1 {
    height: 100vw;
    width: 100%;
    margin: auto;
  }

  .img-after {
    width: 90%; 
    margin: auto;
    margin-top: 30px;
  }

  .textAbout {
    width: 100%;
  }

  .grid-container {
    display: block;
    width: 100%;
  }

  .grid-item {
    background-size: cover;
    height: 100vw;
    width: 100%;
    font-size: 30px;
  }

  .dis-container h2 {
    font-size: 28px;
    width: 90%;
    margin: auto;
  }

  .first-title {
    padding-top: 30px;
  }

  .img-block-1 {
    height: 300px;
  }
}
